// TODO: Hardik/Faizan - try passing attribute to list card - like - highlight: { flag: true, hit, attribute: 'userEventNumber' }

import { Highlight } from 'react-instantsearch';

import {
  CART_STATUS,
  HOST_INTERACTION_STATUS,
  NAVIGATION_ENTITY_TYPE,
  ORDER_STATUS,
  USER_EVENT_STAGE
} from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat
} from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getEditOrderURL } from '@/services/opsPortal.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const parseUserEventEntity = (hit) => {
  const {
    entityId,
    eventDate,
    eventTime,
    eventType,
    hostMobile,
    hostName,
    hostNumber,
    plannerName,
    stage
  } = hit;
  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'user event number',
        icon: 'event-red-icon.svg',
        label: (
          <Highlight
            {...{
              attribute: 'userEventNumber',
              hit
            }}
          />
        )
      },
      entityStatus: {
        statusClass: USER_EVENT_STAGE[stage].class,
        value: USER_EVENT_STAGE[stage].label
      }
    },
    header: {
      subTitle: {
        alt: 'zendesk ticket number',
        icon: 'zendesk-with-frame.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'zendeskTicketNumber',
              hit
            }}
          />
        )
      },
      title: eventType,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'planner name',
          icon: 'planner-icon-orange.svg',
          label: plannerName
        },
        {
          alt: 'last updated',
          icon: 'calendar-red-with-frame.svg',
          label: `${convertToDateTimeString({ date: eventDate, time: eventTime })}`
        },
        {
          alt: 'host name',
          icon: 'user-red-with-frame.svg',
          label: hostName
        },
        {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber)
        }
      ]
    },
    icon: {
      alt: 'event',
      name: 'list-card-event.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.EVENT_CART_LIST.label,
          pathParams: { userEventId: entityId }
        }),
        '_blank'
      );
    }
  };
};

const parseCartEntity = (hit) => {
  const {
    cartTotal,
    createdBy,
    entityId,
    hostMobile,
    hostName,
    hostNumber,
    status
  } = hit;
  const { className: cartStatusClass, label: cartStatusLabel } =
    CART_STATUS[status.toUpperCase()];
  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'cart number',
        icon: 'cart-order-icon-with-frame-red.svg',
        label: (
          <Highlight
            {...{
              attribute: 'cartNumber',
              hit
            }}
          />
        )
      },
      entityStatus: {
        statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
        value: cartStatusLabel
      }
    },
    header: {
      subTitle: {
        alt: 'user event number',
        icon: 'event-red-icon.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'userEventNumber',
              hit
            }}
          />
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'cartName',
            hit
          }}
        />
      ),
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'planner name',
          icon: 'planner-icon-orange.svg',
          label: createdBy
        },
        {
          alt: 'cart total',
          icon: 'price-icon.svg',
          label: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(cartTotal)
          })
        },
        {
          alt: 'host name',
          icon: 'user-red-with-frame.svg',
          label: hostName
        },
        {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber)
        }
      ]
    },
    icon: {
      alt: 'cart',
      name: 'list-card.svg'
    },
    onClick: () => {
      const pageName =
        status === CART_STATUS.ORDER.value
          ? PAGE_NAME.ORDERED_CART.label
          : PAGE_NAME.PLANNER_CART.label;
      window.open(
        getPageURL({
          pageName,
          pathParams: { cartId: entityId }
        }),
        '_blank'
      );
    }
  };
};

const parseOrderEntity = (hit) => {
  const {
    bookingDate,
    entityId,
    orderName,
    orderTotal,
    hostMobile,
    hostName,
    hostNumber,
    status
  } = hit;
  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'order number',
        icon: 'cart-order-icon-bold.svg',
        label: (
          <Highlight
            {...{
              attribute: 'orderNumber',
              hit
            }}
          />
        )
      },
      entityStatus: {
        statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
        value: ORDER_STATUS[status]?.label
      }
    },
    header: {
      subTitle: {
        alt: 'user event number',
        icon: 'event-red-icon.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'userEventNumber',
              hit
            }}
          />
        )
      },
      title: orderName,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
    },
    hoverCard: {
      cta: {
        label: 'Manage Order',
        onClick: () => {
          window.open(getEditOrderURL({ orderId: entityId }), '_blank');
        }
      },
      textWithIconList: [
        {
          alt: 'booking date',
          icon: 'order-date.svg',
          label: convertToShortMonthDateFormat(bookingDate)
        },
        {
          alt: 'order total',
          icon: 'price-icon.svg',
          label: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(orderTotal)
          })
        },
        {
          alt: 'host name',
          icon: 'user-red-with-frame.svg',
          label: hostName
        },
        {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber)
        }
      ]
    },
    icon: {
      alt: 'order',
      name: 'list-card-order.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.ORDER_VIEW.label,
          pathParams: { orderId: entityId }
        }),
        '_blank'
      );
    }
  };
};

const parseHostEntity = (hit) => {
  const {
    hostSegment,
    email,
    entityId,
    hostCreditEntityName,
    hostMobile,
    interactionStatus
  } = hit;

  const parsedHostCreditEntityName =
    hostCreditEntityName === 'NA' ? null : hostCreditEntityName;

  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'host number',
        icon: 'number-icon.svg',
        label: (
          <Highlight
            {...{
              attribute: 'hostNumber',
              hit
            }}
          />
        )
      },
      entityStatus: {
        statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
        value: HOST_INTERACTION_STATUS[interactionStatus].label
      }
    },
    header: {
      subTitle: {
        alt: 'credit entity name',
        icon: 'host-credit-entity-red-icon.svg',
        isBorder: true,
        value: parsedHostCreditEntityName && (
          <Highlight
            {...{
              attribute: 'hostCreditEntityName',
              hit
            }}
          />
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'hostName',
            hit
          }}
        />
      ),
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'host type',
          icon: 'user-red-with-frame.svg',
          label: hostSegment || 'NA'
        },
        {
          alt: 'email',
          icon: 'email-icon.svg',
          label: email
        }
      ]
    },
    icon: {
      alt: 'host',
      name: 'list-card-host.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.HOST_EVENT_LIST.label,
          pathParams: { userId: entityId }
        }),
        '_blank'
      );
    }
  };
};

const parseHostCreditEntity = (hit) => {
  const { accountManagerName, entityId } = hit;
  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'host credit entity number',
        icon: 'host-credit-entity-red-icon.svg',
        label: (
          <Highlight
            {...{
              attribute: 'hostCreditEntityNumber',
              hit
            }}
          />
        )
      }
    },

    header: {
      subTitle: {
        alt: 'account manager',
        icon: 'planner-red.svg',
        isBorder: true,
        value: accountManagerName
      },
      title: (
        <Highlight
          {...{
            attribute: 'hostCreditEntityName',
            hit
          }}
        />
      )
    },
    icon: {
      alt: 'host credit entity',
      name: 'list-cart-host-credit-entity.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
          pathParams: { hostCreditEntityId: entityId }
        }),
        '_blank'
      );
    }
  };
};

export const transformSearchResultList = ({ searchResults }) => {
  const transformFunctionMap = {
    [NAVIGATION_ENTITY_TYPE.CART]: parseCartEntity,
    [NAVIGATION_ENTITY_TYPE.HOST]: parseHostEntity,
    [NAVIGATION_ENTITY_TYPE.ORDER]: parseOrderEntity,
    [NAVIGATION_ENTITY_TYPE.USER_EVENT]: parseUserEventEntity,
    [NAVIGATION_ENTITY_TYPE.HOST_CREDIT_ENTITY]: parseHostCreditEntity
  };

  return searchResults.map(({ entityType, ...rest }) =>
    transformFunctionMap[entityType]({ ...rest, entityType })
  );
};

export default transformSearchResultList;
