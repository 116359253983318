import Image from 'next/image';
import { useRouter } from 'next/router';

import {
  HostEventStatistics,
  LetsPlanFirstEvent
} from '@/components/atomic/atoms';
import {
  Avatar,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  HOST_INTERACTION_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { isEmptyOrNull, parseNumberedLabel } from '@/lib/utils';
import { getHostTouchPoints } from '@/services/hostEvent.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const CommonTextWithIcon = ({ icon, label }) => {
  const show = Boolean(label);
  return (
    <div className={`mt-4 ${show ? 'flex' : 'hidden'}`}>
      <TextWithIcon
        {...{
          alt: icon,
          className: 'gap-2 border border-neutral rounded-lg py-1 px-2',
          icon,
          iconHeight: 24,
          iconWidth: 24,
          label,
          labelStyle: 'font-medium text-dim-gray',
          show
        }}
      />
    </div>
  );
};

const HostProfileEventListSidebar = ({
  user: {
    createdAt,
    hostSegment,
    email,
    ethnicity,
    hostCreditEntity,
    hostNumber,
    id,
    interactionStatus,
    mobile,
    name,
    preferredLanguage
  },
  hostEventStatistics
}) => {
  const router = useRouter();
  const hostTouchPoints = getHostTouchPoints({
    email,
    mobile,
    requesterType: hostSegment?.name
  });
  const { id: hostCreditEntityId, name: hostCreditEntityName } =
    hostCreditEntity || {};
  const showLetsPlanFirstEvent =
    interactionStatus === HOST_INTERACTION_STATUS.NEW.value;

  const toolTipConfigList = [
    {
      target: 'viewHostCreditEntityHostList',
      text: 'Credit Entity'
    }
  ];
  return (
    <div className='fixed w-108 h-full left-0 -mt-[4rem]'>
      <div className='bg-white h-[100vh] px-8 py-4  pt-16'>
        <div className='flex items-center gap-4'>
          <div className='w-[100px] h-[100px] rounded-lg my-4'>
            <Avatar {...{ name }} />
          </div>
          <div className='flex flex-col w-[21rem]'>
            <Text
              {...{
                className:
                  'text-brand text-xl font-semibold max-w-[16rem] truncate',
                content: name
              }}
            />
            {!isEmptyOrNull(hostCreditEntityId) && (
              <div className='flex items-center justify-between'>
                <Text
                  {...{
                    className:
                      'text-nero font-medium my-2 max-w-[18rem] truncate',
                    content: hostCreditEntityName
                  }}
                />
                <Image
                  alt='view host credit entity host list'
                  height={0}
                  id='viewHostCreditEntityHostList'
                  onClick={() =>
                    router.push(
                      getPageURL({
                        pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
                        pathParams: { hostCreditEntityId }
                      })
                    )
                  }
                  priority={true}
                  src={`${staticMediaStoreBaseURL}/icons/view-red-icon.svg`}
                  style={{
                    height: 20,
                    width: 20
                  }}
                  width={0}
                />
                <Tooltip {...{ configList: toolTipConfigList }} />
              </div>
            )}
          </div>
        </div>
        <div className='flex justify-between mt-1'>
          <TextWithIcon
            {...{
              alt: 'view host profile',
              className:
                'flex items-center gap-2 border border-brand py-1 rounded-lg cursor-pointer px-2',
              icon: 'view-red.svg',
              iconHeight: 16,
              iconWidth: 16,
              label: 'Host Profile',
              labelStyle: 'text-sm font-medium text-brand',
              onClick: () => {
                router.push(
                  getPageURL({
                    pageName: PAGE_NAME.HOST_PROFILE.label,
                    pathParams: {
                      userId: id
                    }
                  })
                );
              }
            }}
          />

          <div className='flex gap-4'>
            <Text
              {...{
                className: `text-sm font-medium px-2 py-1 rounded-lg ${HOST_INTERACTION_STATUS[interactionStatus]?.className}`,
                content: HOST_INTERACTION_STATUS[interactionStatus]?.label
              }}
            />
            <TextWithIcon
              {...{
                alt: 'number',
                className: 'rounded-lg border border-neutral gap-1 px-2 py-1',
                icon: 'number-icon.svg',
                iconHeight: 20,
                iconWidth: 20,
                label: parseNumberedLabel(hostNumber),
                labelStyle: 'text-dim-gray text-sm font-medium'
              }}
            />
          </div>
        </div>
        {hostTouchPoints.map(
          (
            {
              alt,
              className,
              icon,
              iconHeight,
              iconWidth,
              label,
              labelStyle,
              show
            },
            index
          ) => (
            <TextWithIcon
              key={index}
              {...{
                alt,
                className,
                icon,
                iconHeight,
                iconWidth,
                label,
                labelStyle,
                show
              }}
            />
          )
        )}

        <div className='flex gap-4'>
          <CommonTextWithIcon
            {...{
              icon: 'country-icon.svg',
              label: ethnicity?.name
            }}
          />
          <CommonTextWithIcon
            {...{
              icon: 'language-icon.svg',
              label: preferredLanguage?.name
            }}
          />
        </div>

        <div className='flex justify-center fixed bottom-8 w-[27rem] h-[280px]'>
          {showLetsPlanFirstEvent ? (
            <LetsPlanFirstEvent
              {...{
                createdAt,
                labelClassName: 'text-brand'
              }}
            />
          ) : (
            <HostEventStatistics
              {...{
                createdAt,
                hostEventStatistics
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HostProfileEventListSidebar;
