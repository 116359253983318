import { HostCreditEntityName } from '@/components/atomic/atoms';
import { HostCreditEntitySideBarBody } from '@/components/atomic/molecules';
import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';

const HostCreditEntityProfileSideBarOrganism = ({
  hostCreditEntityCreditRequests,
  hostCreditEntityProfileDetails: {
    hostCreditEntity,
    hostCreditEntityStatistics: { creditFacilityInformation, eventStatistics }
  }
}) => {
  const { createdAt, hostCreditEntityNumber, name, updatedAt } =
    hostCreditEntity;

  const showLetsPlanFirstEvent = eventStatistics.inquiredEventCount === 0;

  return (
    <div className='fixed z-50 w-108 h-full left-0 bg-host-event-sidebar bg-no-repeat bg-cover text-white overflow-auto'>
      <div className='h-screen px-8 py-4'>
        <HostCreditEntityName
          {...{
            avatar: {
              badgeStyle: 'bg-white w-[100px] h-[100px]',
              name,
              textStyle: 'text-brand-gradient'
            },
            label: {
              className: 'text-white text-xl font-semibold',
              content: name
            }
          }}
        />
        <div className='flex items-center justify-between mt-1 mb-10'>
          <Text
            {...{
              className: 'text-xs text-white',
              content: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
            }}
          />
          <TextWithIcon
            {...{
              alt: 'credit entity number',
              className:
                'rounded-lg border border-neutral gap-1 px-2 py-1 bg-white',
              icon: 'host-credit-entity-red-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              label: parseNumberedLabel(hostCreditEntityNumber),
              labelStyle: 'text-dim-gray text-sm font-medium'
            }}
          />
        </div>
        <HostCreditEntitySideBarBody
          {...{
            applyForCreditBannerClass: {
              bannerStyle: 'bg-white-opacity',
              labelStyle: 'text-white',
              valueStyle: 'text-white text-sm'
            },
            createdAt,
            creditFacilityInformation,
            creditUtilizationStyle: 'bg-white-opacity',
            eventStatistics,
            hostCreditEntityCreditRequests,
            letsPlanFirstEvent: {
              show: showLetsPlanFirstEvent,
              labelClassName: 'text-white'
            }
          }}
        />
      </div>
    </div>
  );
};

export default HostCreditEntityProfileSideBarOrganism;
